import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const h1b = `${HOST}web-h1b`

export default {
  verify(context1, token) {
    const context = context1
    axios.get(`${h1b}/${token}`).then(response => {
      context.setValid(response.data)
    }, error => {
      context.setNotValid(error.response.data.return_message)
    })
  },
  intrest(context1, token) {
    const context = context1
    axios.get(`${h1b}/intrested/${token}`).then(response => {
      context.h1bUserIntrested(response.data)
    }, error => {
      context.h1bUserIntrestedFailed(error.response.data.return_message)
    })
  },
  addH1bUserDetails(context1, token, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${h1b}/${token}`, body).then(response => {
      context.$vs.loading.close()
      context.h1bUserDetailsAdded(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.h1bUserDetailsNotAdded(error.response.data.return_message)
    })
  },
  getDocumentNameFullList(context) {
    axios.get(`${h1b}/document-names`).then(response => {
      context.setDocumentNames(response.data)
    })
  },
  getReferrerTypes(context) {
    axios.get(`${h1b}/referrer-types`).then(response => {
      context.setReferrerTypes(response.data)
    })
  },

}
