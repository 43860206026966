<template>
  <div class="horizontal-layout horizontal-menu navbar-floating footer-static">
    <nav class="navbar header-navbar align-items-center navbar-brand-center navbar-light navbar-expand">
      <div class="navbar-container text-center">
        <span class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo-datasoft.png"
            width="100"
            alt="Logo"
          />
        </span>
      </div>
    </nav>
    <div class="bg-primary py-1">
      <div class="text-center">
        <h2 class="text-white mb-0">
          H-1B Intrest
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import H1B from '@/js/h1b'

export default {
  components: {
    BImg,
  },
  data() {
    return {
    }
  },
  created() {
    H1B.intrest(this, this.$route.params.token)
  },
  methods: {
    h1bUserIntrested(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: message.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          window.location.href = 'https://datasoftglobal.net'
        }
      })
    },
    h1bUserIntrestedFailed(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          window.location.href = 'https://datasoftglobal.net'
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
